import React from 'react';
import Styles from './link-section.module.scss';
import Helmet from 'react-helmet';

export default function({ latestYoutubeVideoId, latestYouTubeVideoTitle }) {
    return (
        <React.Fragment>
            <Helmet>
                <title>Mindfoe</title>
            </Helmet>
            <section className={`${Styles.section} container py-4 position-relative`}>
                <div className="row mb-5">
                    <div className="col-10 offset-1 col-sm-12 offset-sm-0 text-center mb-lg-5">
                        <img className="w-100" alt="" src="/logo/mindfoe-brain-long.svg"></img>
                    </div>
                </div>
                <div className="row justify-content-center justify-content-sm-around">
                    <div className={`col-11 col-sm-5 position-relative text-center ${Styles.card}`}>
                        <img src="/links/icons/web.svg" loading="lazy" alt=""></img>
                        <h1>Website</h1>
                        <small>
                            <a className="stretched-link" href="https://www.mindfoe.de" target="_self" rel="noopener nofollow">mindfoe.de</a>
                        </small>
                    </div>
                    <div className={`col-11 col-sm-5 position-relative text-center ${Styles.card}`}>
                        <img src="/links/icons/facebook.svg" loading="lazy" alt=""></img>
                        <h1>Facebook</h1>
                        <small>
                            <a className="stretched-link" href="https://www.facebook.com/mindfoe" target="_self" rel="noopener nofollow">facebook.com/mindfoe</a>
                        </small>
                    </div>
                    <div className={`col-11 col-sm-5 position-relative text-center ${Styles.card}`}>
                        <img src="/links/icons/youtube.svg" loading="lazy" alt=""></img>
                        <h1>YouTube</h1>
                        <small>
                            <a className="stretched-link" href="https://www.youtube.com/channel/UCPkqZTUbqzJNfTb3EU0b0-w" target="_self" rel="noopener nofollow">youtube.com/mindfoe</a>
                        </small>
                    </div>
                    <div className={`col-11 col-sm-5 position-relative text-center ${Styles.card}`}>
                        <img src="/links/icons/youtube.svg" loading="lazy" alt=""></img>
                        <h1>Neuestes Video</h1>
                        <small>
    <a className="stretched-link" href={'https://www.youtube.com/watch?v=' + latestYoutubeVideoId} target="_self" rel="noopener nofollow">{latestYouTubeVideoTitle}</a>
                        </small>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
