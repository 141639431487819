import { graphql } from 'gatsby';
import React from 'react';
import Footer from '../components/global/footer/footer';
import MailchimpSignup from '../components/global/mailchimp-signup/mailchimp-signup';
import RecentPosts from '../components/global/recent-posts/recent-posts';
import LinkSection from '../components/links/link-section/link-section';

export default function({ data: { allPrismicBlogPost: posts, youTubeVideos } }) {

  return (
    <React.Fragment>
      <LinkSection latestYoutubeVideoId={youTubeVideos.items[0].alternative_id.videoId} latestYouTubeVideoTitle={youTubeVideos.items[0].snippet.title}/>
      <MailchimpSignup alwaysVertical={false} outerClass="py-5" slice={{
        primary: {
          title1: {
            text: 'Trage dich in meinen Newsletter ein!'
          },
          text: {
            html: '<p>Wenn du immer auf dem neusten Stand sein willst & geniale Tipps rund um die Themen Abnehmen, Lifestyle & Veränderung bekommen möchtest würde ich dir Nahe legen, dich bei meinen Newsletter anzumelden!</p><p>100% kostenlos und garantiert ohne Spam!</p>'
          }
        }
      }} />
      <div className="container pt-5">
        <div className="row">
          <div className="col-12">
            <h1 style={{
              marginTop: 0,
              fontSize: '1.6rem'
            }}>Neuester Beitrag</h1>
          </div>
        </div>
      </div>
      <RecentPosts hideLatestPostCaption={true} posts={{
        nodes: [
          posts.nodes[0]
        ]
      }} blackWhiteImages={true}/>
      <Footer posts={posts} maxPosts={3}/>
    </React.Fragment>
  );
}


export const pageQuery = graphql`
  query LinksPage {
    youTubeVideos {
      items {
        snippet {
          title
          description
          liveBroadcastContent
        }
        alternative_id {
          videoId
        }
      }
    }
    allPrismicBlogPost(limit: 3, sort: {fields: first_publication_date, order: DESC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
  }
`
